@media (min-width: 990px) {
  .features--1-lXS {
    flex-direction: row-reverse; } }

.features__image--3DGKs {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 990px) {
    .features__image--3DGKs {
      justify-content: flex-end; } }
  @media (max-width: 989px) {
    .features__image--3DGKs {
      flex-wrap: nowrap;
      overflow-x: auto; }
      .features__image_3-pic--39pRr, .features__image_4-pic--M1qFN {
        margin-left: -20px;
        margin-right: -20px; } }
  @media (min-width: 990px) {
    .features__image-item--_AS-H {
      flex: 1 1 225px;
      margin-bottom: 10px;
      border-radius: 6px; }
      .features__image-item_1-pic---X3NZ, .features__image-item_2-pic--ZMjn8, .features__image-item_3-pic--1Vilc:nth-child(1) {
        flex: 1 1 550px; }
      .features__image-item_3-pic--1Vilc:nth-child(2), .features__image-item_4-pic--2msKb:nth-child(odd) {
        margin-right: 10px; } }
  @media (max-width: 989px) {
    .features__image-item--_AS-H {
      flex: 0 0 155px;
      margin-right: 10px;
      border-radius: 4px; }
      .features__image-item_1-pic---X3NZ, .features__image-item_2-pic--ZMjn8 {
        flex: 1; }
      .features__image-item_2-pic--ZMjn8:nth-child(2) {
        margin-right: 0; }
      .features__image-item_3-pic--1Vilc:first-child, .features__image-item_4-pic--2msKb:first-child {
        margin-left: 20px; } }
  .features__image-item_1-pic---X3NZ {
    margin-right: 0; }
  .features__image-item_1-pic---X3NZ, .features__image-item_2-pic--ZMjn8:nth-child(2), .features__image-item_3-pic--1Vilc:nth-child(2), .features__image-item_3-pic--1Vilc:nth-child(3), .features__image-item_4-pic--2msKb:nth-child(3), .features__image-item_4-pic--2msKb:nth-child(4) {
    margin-bottom: 0; }

.features__unit--UMEJy {
  flex: 1 1 100%;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1); }
  .features__unit-header--gNKQt {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0); }
    .features__unit-header-arrow--144yk {
      --svg-primary-color: hsla(0, 0%, 65%, 1); }
      .features__unit-header-arrow_active--1jxpS svg {
        transform: rotate(180deg);
        --svg-primary-color: hsla(0, 0%, 15%, 1); }
      .features__unit-header-arrow--144yk svg {
        transition: all 0.5s ease 0s; }
  .features__unit-content--3fLel {
    display: none; }
    .features__unit-content_visible--1KDey {
      display: block; }
